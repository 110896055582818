import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
	// {
	// 	path: '/',
	// 	name: 'home',
	// 	component: () => import('../views/home/index.vue')
	// },
	{
		path: '/index',
		name: 'index',
		component: () => import('../components/MainContent/MainContent.vue'),
		children: [
		    {
		        path: '/',
				name: 'home',
				meta: {moudule: 'home'},
		        component: () => import('../views/home/index.vue')
			},
			{
				path: '/about',
				name: 'about',
				meta: {moudule: 'about'},
				component: () => import('../views/home/about.vue')
			},
			{
				path: '/download',
				name: 'download',
				meta: {moudule: 'download'},
				component: () => import('../views/home/download.vue')
			},
			{
				path: '/productcenter/:name?/:cur?/:code?',
				name: 'productcenter',
				meta: {moudule: 'productcenter'},
				props: true,
				component: () => import('../views/home/productcenter.vue')
			},
			{
				path: '/productinfo/:id',
				name: 'productinfo',
				props: true,
				meta: {moudule: 'productcenter'},
				component: () => import('../views/home/productinfo.vue')
			},
			{
				path: '/document',
				name: 'document',
				meta: {moudule: 'document'},
				component: () => import('../views/home/document.vue')
			},
			{
				path: '/api',
				name: 'api',
				meta: {moudule: 'document', shadow: true},
				component: () => import('../views/home/api/index.vue'),
				children: [
				    {
				        path: 'history',
						name: 'history',
						meta: {moudule: 'document', shadow: true},
				        component: () => import('../views/home/api/history.vue')
					},
				    {
				        path: 'info',
						name: 'info',
						meta: {moudule: 'document', shadow: true},
				        component: () => import('../views/home/api/info.vue')
					},
				    {
				        path: 'method',
						name: 'method',
						meta: {moudule: 'document', shadow: true},
				        component: () => import('../views/home/api/method.vue')
					},
				    {
				        path: 'websocket',
						name: 'websocket',
						meta: {moudule: 'document', shadow: true},
				        component: () => import('../views/home/api/websocket.vue')
					},
				    {
				        path: 'request/:id',
						name: 'request',
						meta: {moudule: 'document', shadow: true},
						props: true,
				        component: () => import('../views/home/api/request.vue')
					},
				],
			},
		],
	},
	{
		path: '/living',
		name: 'living',
		component: () => import('../views/living/living.vue')
	},
	{
		path: '/meeting',
		name: 'meeting',
		component: () => import('../components/Content/Content.vue'),
		children: [
		    {
		        path: 'index',
		        component: () => import('../views/meeting/index.vue')
			},
		    {
		        path: 'meeting/:room',
		        component: () => import('../views/meeting/Meeting.vue'),
				props: true
			},
		    {
		        path: 'living/:url/:room',
		        component: () => import('../views/meeting/Living.vue'),
				props: true
			},
		],
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/meeting/Login.vue')
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
