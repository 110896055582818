import { localGet } from '@/config/storage'
export default {
    namespaced: true,
	state: () => ({
		/**用户数据**/
		meetingData: localGet('meetingData') || JSON.stringify({}),
		livingData: localGet('livingData') || JSON.stringify({}),
		companyData: {
			name: '厦门云视听信息技术有限公司',
			address: '福建省厦门市湖里区湖里大道43号405室之2',
			email: '125388771@qq.com',
			phone: '18150151002',
			qq: '125388771',
		}
	}),
	mutations: {
		/**保存当前选中摄像头**/
		setMeetingData(state:any, value:any){
			state.meetingData = value
		},
		setLivingDataa(state:any, value:any){
			state.livingData = value
		},
	},
	actions: {
		
	}
}