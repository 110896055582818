import { createStore } from 'vuex'
import websocketOptions from '@/store/public/websocket'
import publicOptions from '@/store/public/public'

export default createStore({
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		websocketOptions,
		publicOptions,
	}
})
