import store from '@/store'
import { nextTick } from 'vue'
/**创建每个流对象**/
export const Participant = function(this: any, name: string, camera?:boolean, muteFlag?:boolean, mode?: string, readyStatus?:any, videoWidth?: number, videoHeight?: number){
	camera = typeof (camera) == "undefined" ? true : camera
	muteFlag = typeof (muteFlag) == "undefined" ? false : muteFlag
	readyStatus = typeof (readyStatus) == "undefined" ? 0 : readyStatus
	this.name = name
	this.mode = mode || 'none'
	// this.muteStatus = 1 //开麦状态，1开麦，0禁麦
	// this.videoStatus = 1 //视频状态，1开启视频，0视频关闭
	this.muteStatus = muteFlag ? 0 : 1 //开麦状态，1开麦，0禁麦
	this.videoStatus = camera ? 1 : 0 //视频状态，1开启视频，0视频关闭
	this.readyStatus = readyStatus //视频加载状态，0加载未完成，1加载完毕开始播放,-1加载失败，不能播放
	this.mkStatus = 0 //麦克风状态，是否能用，默认不能用，0不能用，1可以用
	this.cameraStatus = 1 //摄像头状态，是否能用，默认不能用，0不能用，1可以用
	this.checkeds = false //播放器加载完成回调是否已经调用过
	this.onIceCandidate = function (candidate: any, wp: any) {
		let message = {
		    method: 'onIceCandidate',
			params:{
				candidate: candidate,
				name: name
			}
		};
		sendMessage(message);
	}
	this.offerToReceiveVideo = function(error: any, offerSdp: any, wp: any){
		if (error) return console.log("sdp offer error", error)
		console.log('Invoking SDP offer callback function');
		let message =  {
			method : "receiveVideoFrom",
			params:{
				sender : name,
				sdpOffer : offerSdp
			}
		};
		sendMessage(message);
	}
	this.onaddstream = function(e:any){
		
	}
	Object.defineProperty(this, 'rtcPeer', { writable: true});
	this.dispose = function() {
		this.rtcPeer.dispose()
	}
	//加载错误
	this.getError = function(){
		this.muteStatus = 0
		this.videoStatus = 0
		this.readyStatus = -1
		this.cameraStatus = 0
		let message = {
			method: 'mute',
			params: {
				sender: name
			}
		}
		sendMessage(message)
		let message2 = {
			method: 'groupForward',
			params: {
				key: "camera",
				method: "updateMode",
				value: false,
				params: {
					name,
					camera: false,
				}
			}
		}
		sendMessage(message2)
	}
	//设置开麦禁麦状态
	this.setMute = function(muteFlag:any){
		this.muteStatus = muteFlag
	}
	//设置参数
	this.setPara = function(data:any){
		for(let i in data){
			this[i] = data[i]
			if(i == 'videoStatus'){
				const camera = data[i] == 1
				let message = {
					method: 'groupForward',
					params: {
						key: "camera",
						method: "updateMode",
						value: camera,
						params: {
							name,
							camera,
						}
					}
				}
				sendMessage(message)
				sendMessage({
					method: 'groupForward',
					params: {
						method: "videoStop",
						params: {
							name,
						}
					}
				})
			}
		}
	}
	this.setVideo = function(){
		const audio:any = document.getElementById('video' + name)
		audio.srcObject = null
	}
	//设置视频开启状态
	this.setCamera = function(muteFlag:any){
		this.videoStatus = muteFlag
	}
	//禁麦/开麦，或者打开关闭视频
	this.setMuted = function(type:string){
		const audio:any = document.getElementById('video' + name)
		const stream = audio.srcObject
		
		let isMuted = type == 'audio' ? this.muteStatus == 0 : this.videoStatus == 0
		stream.getTracks().forEach((track:any)=>{
			if(track.kind == type) track.enabled = isMuted
		})
		if(type == 'audio'){
			this.muteStatus = !isMuted ? 0 : 1
			let msgId = isMuted ? 'unmute' : 'mute'
			let message = {
				method: msgId,
				params: {
					sender: name
				}
			}
			sendMessage(message)
		}else{
			this.videoStatus = !isMuted ? 0 : 1
			const camera = this.videoStatus == 1
			let message = {
				method: 'groupForward',
				params: {
					key: "camera",
					method: "updateMode",
					value: camera,
					params: {
						name,
						camera,
					}
				}
			}
			sendMessage(message)
		}
	}
	//视频/音频可以加载完毕可以播放后监控声音画面
	this.canPlay = async function (callback:any){
		if(this.checkeds){
			return false
		}
		this.checkeds = true
		this.readyStatus = 1
		var audio:any = document.getElementById('video' + name)
		var stream = audio.srcObject
		var isMuted = true
		var videoMuted = true
		let tracks = stream.getTracks()
		await nextTick()
		tracks.forEach(async (track:any)=>{
			if(track.kind == 'audio' && track.enabled == true && track.muted == false){
				if(!muteFlag){
					isMuted = false
					this.mkStatus = 1
				}
			}else if(track.kind == 'video' && track.enabled == true && track.muted == false){
				videoMuted = false
			}else if(track.kind == 'video' && track.enabled == true){
				videoMuted = false
			}
		})
		this.muteStatus = isMuted ? 0 : 1
		this.videoStatus = videoMuted ? 0 : 1
		if(!isMuted){
			if(typeof callback == 'function') callback(stream)
		}
	}
}
/**发送消息**/
function sendMessage(message: any){
	if(store){
		store.dispatch('websocketOptions/sendMessage', message)
	}
}