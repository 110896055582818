import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './config/element'

import '@/assets/font/iconfont.css'
import '@/assets/font_cai/iconfont.css'
import '@/assets/css/app/style.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import VueBMap, {initBMapApiLoader} from 'vue-bmap-gl'
import 'vue-bmap-gl/dist/style.css'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.$filters = {
	formatTime(value:any) {
		if(!value){
			return '00:00:00'
		}else{
			let hour = this.numFromat(parseInt((value / 3600).toString()))
			let hourYu = this.numFromat(value % 3600)
			let min = this.numFromat(parseInt((hourYu / 60).toString()))
			let sec = this.numFromat(hourYu % 60)
			return hour + ':' + min + ':' + sec
		}
	},
	numFromat(value:number){
		return value >= 10 ? value : '0' + value
	},
	nameFromat(value:string){
		return value.split(' ')
	}
}
installElementPlus(app)

initBMapApiLoader({
  ak: 'sWVzBQ8S13r7sBpUf3vKzK88sFsGmdpf',
  v: '1.1',
})

app.use(store).use(router).use(VueBMap).mount('#app')
